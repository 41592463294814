<template>
  <div>
    <v-card v-if="!fetchinData">
      <v-toolbar dense dark color="dark">
        <v-toolbar-title>{{ $t("Activity") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="2">
            <v-switch
              false-value="0"
              true-value="1"
              v-model="data.isContacted"
              inset
              :label="$t('Contacted')"
              @change="toggleChange('contacted')"
            ></v-switch>
          </v-col>

          <v-col cols="12" md="2">
            <v-switch
              false-value="0"
              true-value="1"
              v-model="data.isScheduled"
              inset
              :label="$t('Scheduled')"
              @change="toggleChange('scheduled')"
            ></v-switch>
          </v-col>

          <v-col cols="12" md="2">
            <v-switch
              false-value="0"
              true-value="1"
              v-if="pay && pay !== '0' && pay != null && pay !== 0"
              v-model="data.hasRedeemed"
              inset
              :label="$t('Bought')"
              @change="toggleChange('begun')"
            ></v-switch>
            <v-switch
              false-value="0"
              true-value="1"
              v-else
              v-model="data.hasRedeemed"
              inset
              :label="$t('Redeemed')"
              @change="toggleChange('begun')"
            ></v-switch>
          </v-col>

          <v-col cols="12" md="2" v-if="showClient && !confettiActive">
            <v-switch
              false-value="0"
              true-value="1"
              v-model="data.identityIsClient"
              inset
              :label="$t('client')"
              @change="toggleChange('client')"
            ></v-switch>
          </v-col>

          <v-col cols="12" md="2" v-else>
            <v-switch
              false-value="0"
              true-value="1"
              v-model="data.identityIsClient"
              inset
              :label="$t('client')"
              disabled
            ></v-switch>
          </v-col>

          <v-col v-if="showLost" cols="12" md="2">
            <v-switch
              false-value="0"
              true-value="1"
              v-model="data.isLost"
              inset
              :label="$t('Lost')"
              :disabled="data.identityIsClient === '1' ? true : false"
              @change="toggleChange('lost')"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("areYouSure") }}
        </v-card-title>

        <v-card-text v-html="$t('lostHint')"></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="updateToLost()">{{
            $t("markAsLost")
          }}</v-btn>
          <v-btn color="green darken-1" text @click="cancelLost()">{{
            $t("cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import store from "@/store";
export default {
  name: "StateTogglesCard",

  data() {
    return {
      confettiActive: false,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      dialog: false,
    };
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    pay: {
      type: String,
      required: false,
      default: "0",
    },
    showLost: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClient: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    startConfetti() {
      this.confettiActive = true;
      this.$confetti.start();
      document.getElementById("confetti-canvas").style.zIndex = "99999999";
    },

    stopConfetti() {
      let that = this;
      this.$confetti.stop();
      setTimeout(function () {
        const elem = document.getElementById("confetti-canvas");
        elem.parentNode.removeChild(elem);
        that.confettiActive = false;
      }, 3500);
    },

    cancelLost() {
      this.data.isLost = "nein";
      this.dialog = false;
    },

    updateToLost() {
      this.updateState("isLost", 1);
      this.dialog = false;
    },

    async addNote(note) {
      const formData = new FormData();
      formData.append("note", note);
      this.fetchinData = true;
      let response = await this.postRequest(
        `companies/${this.companyID}/leads/${this.data.id}/notes`,
        null,
        formData
      );
      this.fetchinData = false;

      if (response.status === 200) {
        /* emit, da ich mangels daten den array nicht mutieren kann.
        durch das emit starte ich den fetch erneut */
        this.$emit("emitted", "noteAdded");
      }
    },

    async updateState(name, value) {
      const params = new URLSearchParams();
      params.append(name, value);

      let response = null;

      this.fetchinData = true;

      response = await this.patchRequest(
        `companies/${this.companyID}/leads/${this.data.id}`,
        params
      );
      this.fetchinData = false;

      if (response.status === 200) {
        /* MUTATE the Base-Data directly here.
          The reactive Data transfered as PROP straight to this Component 
          Die API Response beinhaltet das Value wie es sein muss (response.data.result.value).
          Warum wir nicht immer 1/0 genommen haben, weiß ich nicht ...
        */

        if (name === "isContacted") {
          this.data.isContacted = response.data.data.isContacted;
        } else if (name === "isScheduled") {
          this.data.isScheduled = response.data.data.isScheduled;
        } else if (name === "hasRedeemed") {
          this.data.hasRedeemed = response.data.data.hasRedeemed;
        } else if (name === "isLost") {
          this.data.isLost = response.data.data.isLost;
        }

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        let Noteaction = this.$t("marked");
        if (value === "0") {
          Noteaction = this.$t("demarked");
        }

        let note = `Dieser Lead wurde mit &raquo;${name}&laquo; ${Noteaction}`;
        this.addNote(note);
      }
    },

    toggleChange(type) {
      let value = null;
      let name = null;

      if (type === "contacted") {
        value = this.data.isContacted;
        name = "isContacted";
      } else if (type === "scheduled") {
        value = this.data.isScheduled;
        name = "isScheduled";
      } else if (type === "begun") {
        value = this.data.hasRedeemed;
        name = "hasRedeemed";
      } else if (type === "client") {
        value = this.data.identityIsClient;
        name = "identityIsClient";
      } else if (type === "lost") {
        value = this.data.isLost;
        name = "isLost";
      }

      if (type === "lost" && value === "1") {
        // ask
        this.dialog = true;
      } else {
        if (type === "client" && value === "1") {
          // Yeah, its a client now. Show confetti!
          this.startConfetti();
          let that = this;
          setTimeout(function () {
            that.stopConfetti();
          }, 2000);
        }

        // do it
        this.updateState(name, value);
      }
    },
  },

  computed: {
    //
  },

  mixins: [manageApi, helpers],
};
</script>
