<template>
  <span>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <template v-if="status !== 'canceld'">
          <v-btn
            @click="dialog = true"
            small
            depressed
            color="error"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t("cancelOrder") }}*
          </v-btn>
          <p class="mt-2">
            *) Es wird noch keine Nachricht an den Käufer gesendet
          </p>
        </template>

        <v-btn
          v-else
          small
          depressed
          color="error"
          v-bind="attrs"
          v-on="on"
          :disabled="true"
        >
          {{ $t("orderIsCancelled") }}
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>{{ $t("baseData") }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-form
            ref="form"
            v-model="isFormValid"
            v-on:submit.prevent="exportData()"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    :label="$t('cancelReason')"
                    v-model="cancelledReason"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div class="d-flex align-end flex-column">
                    <v-row>
                      <v-btn color="grey" text @click="dialog = false">{{
                        $t("cancel")
                      }}</v-btn>
                      <v-btn
                        v-if="isFormValid"
                        color="red"
                        dark
                        @click="cancelOrder"
                        :loading="fetchingData"
                      >
                        {{ $t("cancelOrder") }}
                      </v-btn>
                      <v-btn v-else disabled>{{ $t("cancelOrder") }}</v-btn>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
export default {
  name: "CancelOrder",
  props: ["id", "status", "isCancelledReason"],
  data() {
    return {
      dialog: false,
      fetchingData: false,
      companyID: store.getters["auth/current_sid"],
      adminID: store.getters["auth/adminid"],
      isFormValid: false,
      cancelledReason: "",
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  methods: {
    async cancelOrder() {
      const formData = new URLSearchParams();
      formData.append("reason", this.cancelledReason);

      this.fetchingData = true;
      let response = await this.patchRequest(
        `addons/shop/orders/${this.id}/cancel`,
        formData
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.$emit("updated", 1);
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "cancelSuccessMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async askFor() {
      const userChoice = await this.$dialog.confirm({
        text: this.$t("cancelOrderMessage"),
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.cancelOrder();
      }
    },
  },

  mixins: [manageApi, helpers],
};
</script>
