<template>
  <v-card v-if="!fetchingData">
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>{{ $t("order") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-chip
        v-if="orderData && orderData.status === 'canceld'"
        class="ma-2"
        color="red"
        text-color="white"
      >
        {{ $t("orderIsCancelled") }}
      </v-chip>

      <div v-html="showBoughtItems()"></div>

      <div v-if="orderData && orderData.companyName">
        <h2 class="primary--text mb-2">B2B-Kauf</h2>

        {{ orderData.companyName }}<br />
        {{ orderData.street }} {{ orderData.streetNumber }}<br />
        {{ orderData.zip }} {{ orderData.city }}, {{ orderData.countryCode
        }}<br />
        Telefon: {{ orderData.phone }}<br />
        Mobile: {{ orderData.mobile }}<br />
        E-Mail: {{ orderData.email }}<br />
        Steuernummer: {{ orderData.taxId }}<br />
      </div>
    </v-card-text>

    <v-card-actions>
      <CancelOrder
        v-if="orderData"
        :id="orderData.id"
        :status="orderData.status"
        :isCancelledReason="orderData.isCancelledReason"
        @updated="getOrder()"
      />
      <v-spacer></v-spacer>

      <v-btn
        small
        depressed
        color="purple"
        class="white--text"
        @click="downloadDocument(payData.document)"
        >Download PDF</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import CancelOrder from "@/components/lead/manage/CancelOrder.vue";
export default {
  name: "LeadProductsCard",
  props: ["data"],

  components: {
    CancelOrder,
  },

  data() {
    return {
      payData: this.data.pay,
      orderData: null,
      currency: this.data.pay.currencyCode ?? "NONE",
      leadType: this.data.type, // lead type (regular, payContract, payProduct (neu))
      fetchinData: false, // should be true when fetching data
      progress: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      textFieldProps: {
        prependIcon: "mdi-calendar-month",
      },
      fetchingData: false,
    };
  },

  mounted() {
    this.getOrder();
  },

  methods: {
    remindesDates(string) {
      let dates = "<ul>";

      if (string) {
        const myArr = string.split(",");
        myArr.forEach((date) => {
          dates += "<li>" + this.formatDate(date) + "</li>";
        });

        dates += "</ul>";
      }

      return dates;
    },

    async sendReminder(order) {
      // Die Route POST pay/orders/{id}/sendReminder
      const orderId = order.id;

      this.progress = true;
      let response = await this.postRequest(
        `pay/orders/${orderId}/sendReminder`
      );
      this.progress = false;

      if (response.status === 200) {
        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Reminder wurde erfolgreich versendet!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    buildPromoSection(item) {
      let promoSection = "";
      let promoPeriodFactor = null;
      let promoTitle = "Promotion";

      if (item.promoTitle) {
        promoTitle = item.promoTitle;
      }

      if (item.promoRuntimePeriod) {
        if (item.promoRuntimePeriod === "daily") {
          promoPeriodFactor = "Tage";
        } else if (item.promoRuntimePeriod === "weekly") {
          promoPeriodFactor = "Wochen";
        } else if (item.promoRuntimePeriod === "monthly") {
          promoPeriodFactor = "Monate";
        } else if (item.promoRuntimePeriod === "yearly") {
          promoPeriodFactor = "Jahre";
        } else {
          promoPeriodFactor = item.promoRuntimePeriod;
        }
      }

      if (item.promoPrice) {
        promoSection += `
          <tr>
          <td>Promotion</td>
          <td>${promoTitle}: ${item.promoPriceGross} ${this.currency} für ${item.promoRuntimeCount} ${promoPeriodFactor}</td>
          </tr>`;
      }

      return promoSection;
    },

    buildItemSection(item) {
      let priceSection = "";
      let finePrint = "";

      if (
        item.finePrint &&
        item.finePrint !== "" &&
        item.finePrint !== "null"
      ) {
        finePrint = `<tr>
        <td>${this.$t("finePrint")}</td>
        <td class="caption">${item.finePrint}</td>
        </tr>`;
      }

      priceSection += `<table class="default-table font-weight-regular">
        <tbody>
        <tr>
        <td>${this.$t("product")}</td>
        <td>${item.amount}x ${item.title}</td>
        </tr>
        <tr>
        <td>Kosten</td>
        <td>${item.priceTotal} ${this.currency}</td>
        </tr>`;

      //priceSection += this.buildPromoSection(item);

      if (this.orderData && this.orderData.status === "canceld") {
        priceSection += `<tr>
        <td>Stornierungsgrund</td>
        <td>${this.orderData.isCancelledReason}</td>
        </tr>`;
      }

      priceSection += `${finePrint}</tbody></table>`;

      return priceSection;
    },

    showBoughtItems() {
      let itemsList = "";
      const orderedItems = this.payData.items;
      orderedItems.forEach((item) => {
        itemsList += this.buildItemSection(item);
      });
      return itemsList;
    },

    async dateTimeChanged(d) {
      let day = d.getDate();
      let month = d.getMonth() + 1; // January is 0!
      let year = d.getFullYear();
      let h = d.getHours(); //Get the hour (0-23)
      let m = d.getMinutes(); //Get the minute (0-59)
      let s = d.getSeconds(); //Get the second (0-59)

      // leading zero
      if (month.toString().length < 2) month = "0" + month;
      if (day.toString().length < 2) day = "0" + day;
      if (h.toString().length < 2) h = "0" + h;
      if (m.toString().length < 2) m = "0" + m;
      if (s.toString().length < 2) s = "0" + s;

      const dateTime =
        year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;

      const params = new URLSearchParams();
      params.append("action", "updateScheduled");
      params.append("value", "1");
      params.append("date", dateTime);

      this.fetchinData = true;
      const response = await this.putRequest(
        "person_activity/" +
          this.companyID +
          "/" +
          this.data.games_data_gamerId +
          "/" +
          this.data.games_data_gameid,
        params
      );
      this.fetchinData = false;

      if (response.data && response.data.status === "ok") {
        this.data.games_data_gamer_isScheduledDate =
          response.data.result.dateAfter;

        /* CALL SNACKBAR */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    emitted(value) {
      /* kommt von ActivityNotes. via emit gebe ich das event zurück, da ich mangels daten den array nicht mutieren kann.
        durch das emit (2 ebenen) starte ich den fetch erneut */

      if (value === "noteAdded") {
        this.$emit("emitted", "noteAdded");
      }
      this.noteComponentKey += 1; // reRender notes component
    },

    async getOrder() {
      if (!this.data.pay.orders_id) {
        return;
      }
      this.fetchingData = true;
      let response = await this.getRequest(
        `pay/orders/${this.data.pay.orders_id}`
      );
      this.fetchingData = false;
      this.orderData = response.data.data;
    },
  },

  computed: {
    dateTimeValue: {
      get() {
        /* 
        We got: 2021-09-28 15:50:00
        We need: Tue Sep 28 2021 15:50:00 GMT+0200 (Mitteleuropäische Sommerzeit) 
        */

        const value = this.data.games_data_gamer_isScheduledDate;

        if (!value || value === "") {
          return null;
        }
        const dateTimeValue = new Date(value);
        return dateTimeValue;
      },

      set: function () {
        return this.data.games_data_gamer_isScheduledDate;
      },
    },
  },

  mixins: [manageApi, helpers],
};
</script>

<style>
ul.itemslist {
  list-style-type: none !important;
  padding: 0;
}
</style>
